import React from "react"
import PropTypes from "prop-types"

const InteriorPageFooter = (props) => {
  return (
    <section className="interior-page__footer">
        {props.children}
    </section>
  )
}

InteriorPageFooter.propTypes = {
  children: PropTypes.node.isRequired,
}

export default InteriorPageFooter
