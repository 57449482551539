// extracted by mini-css-extract-plugin
export var accentGrey = "#e8e8e8";
export var amber = "#ffc000";
export var barney = "#bb29bb";
export var bgAccentGrey = "variables-module--bg-accentGrey--cdfec";
export var bgAmber = "variables-module--bg-amber--f8408";
export var bgBarney = "variables-module--bg-barney--23f5d";
export var bgChablis = "variables-module--bg-chablis--e3496";
export var bgHoneydew = "variables-module--bg-honeydew--6670a";
export var bgLightGrey = "variables-module--bg-lightGrey--dea1b";
export var bgMidGrey = "variables-module--bg-midGrey--341c6";
export var bgPaleBlue = "variables-module--bg-paleBlue--8f6ae";
export var bgSlateBlue = "variables-module--bg-slateBlue--70140";
export var bgTurquoise = "variables-module--bg-turquoise--2ea56";
export var bgViridian = "variables-module--bg-viridian--b58f3";
export var bgWhite = "variables-module--bg-white--b0870";
export var honeydew = "#f6fff5";
export var jostBold = "variables-module--jost-bold--695fb";
export var jostMed = "variables-module--jost-med--1f00c";
export var largeBp = "1024";
export var lightGrey = "#f5f5f5";
export var mediumBp = "640";
export var midGrey = "#3e3e3e";
export var paleBlue = "#f5f8fc";
export var slateBlue = "#e1eaf6";
export var smallBp = "0";
export var tcAccentGrey = "variables-module--tc-accentGrey--4c7c4";
export var tcAmber = "variables-module--tc-amber--ceb74";
export var tcBarney = "variables-module--tc-barney--27800";
export var tcChablis = "variables-module--tc-chablis--ae8d9";
export var tcHoneydew = "variables-module--tc-honeydew--ad062";
export var tcLightGrey = "variables-module--tc-lightGrey--2ca1b";
export var tcMidGrey = "variables-module--tc-midGrey--7b3fa";
export var tcPaleBlue = "variables-module--tc-paleBlue--5d013";
export var tcSlateBlue = "variables-module--tc-slateBlue--5e84b";
export var tcTurquoise = "variables-module--tc-turquoise--2f42c";
export var tcViridian = "variables-module--tc-viridian--82ce9";
export var tcWhite = "variables-module--tc-white--f2913";
export var turquoise = "#2cccd3";
export var viridian = "#1f787c";
export var white = "#fff";
export var xlargeBp = "1200";
export var xxlargeBp = "1740";