import React from "react";
import { Link } from "gatsby"
import PropTypes from "prop-types"

const ExploreCard =(props)=>(
  <div className={props.single ? `row row--inner` : `row row--inner lg_mt-2 mt-2`}>
    <div className="columns small-12">
      <Link to={props.url} className="card card--footer">
        <div className="card__heading card__heading--explore"><div className="columns">Explore More</div></div>
        <div className="row lg_mt-1 mt-1">
          <div className="columns">
            <p><strong>{props.title}</strong></p>
            <p>{props.lead}</p>
          </div>
        </div>
      </Link>
    </div>
  </div>
)

ExploreCard.defaultProps = {
  title: 'YOU FORGOT TO ADD A TITLE PROP',
  url: 'YOU FORGOT TO ADD A URL PROP',
  lead: 'YOU FORGOT TO ADD A LEAD PROP',
};

ExploreCard.propTypes = {
  title: PropTypes.node.isRequired,
  url: PropTypes.node.isRequired,
  lead: PropTypes.node.isRequired,
}

export default ExploreCard;
