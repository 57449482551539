import React from "react";
import { Link } from "gatsby"
import PropTypes from "prop-types"

const UpNextCard =(props)=>(
  <div className="row row--inner">
    <div className="columns small-12">
      <Link to={props.url} className="card card--footer card--subway">
        <div className="card__heading"><div className="columns"><span className={props.supressTopic === 'true' ? 'hidden' : ''}>Next:</span>{props.topic}</div></div>
        <div className="row lg_mt-1 mt-1">
          <div className="columns">
            <p><strong>{props.title}</strong></p>
            <p>{props.lead}</p>
          </div>
        </div>
      </Link>
    </div>
  </div>
)


UpNextCard.defaultProps = {
  title: 'YOU FORGOT TO ADD A TITLE PROP',
  url: 'YOU FORGOT TO ADD A URL PROP',
  lead: 'YOU FORGOT TO ADD A LEAD PROP',
  topic: 'YOU FORGOT TO ADD A TOPIC',
  supressTopic: false
};

UpNextCard.propTypes = {
  title: PropTypes.node.isRequired,
  url: PropTypes.node.isRequired,
  lead: PropTypes.node.isRequired,
  topic: PropTypes.node.isRequired
}

export default UpNextCard;
