import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"
import ShareButtons from "../../components/template-partials/global-components/share-buttons"
import InteriorMenu from "../../components/template-partials/global-components/interior-menu"
import InteriorPageHeader from "../../components/template-partials/global-components/interior-page-header"
import InteriorPageFooter from "../../components/template-partials/global-components/interior-page-footer"
import NewlyDiagnosedSubmenu from "../../components/template-partials/newly-diagnosed/newly-diagnosed-submenu"

import {UpNextCard} from "../../components/template-partials/global-components/footer-cards"


import headingBg from "../../images/2.0-headerbg.jpg"
import headingRound from "../../images/newly-diagnosed-nav-image.jpg"

//Cool! Export your sass Vars from a top line and use them in JS!
import * as SassVars from '../../components/variables.module.scss';

const UnderstandingTreatmentOptionsPage = () => {
  return (
    <Layout>
      <SEO title="Understanding Neuroblastoma Treatment Options" description="In this overview, learn about what therapies may be used to treat your child’s neuroblastoma according to their risk group." />

      <InteriorPageHeader backgroundImage={headingBg} roundImage={headingRound} alt="Understanding the Treatment Options">
        <h1 className="section__heading section__heading--green h1">Understanding <em>the</em> Treatment Options</h1>
      </InteriorPageHeader>

      {/* style={{backgroundColor: SassVars.viridian}} */}

      <Section className="interior-page">
        <div className="row row--inner">
          <div className="columns small-12 large-4">
            <InteriorMenu title={`Newly Diagnosed`}>
              <NewlyDiagnosedSubmenu/>
            </InteriorMenu>
          </div>
          <div className="columns">
            <div className="row">
              <div className="columns">
                <ShareButtons/>
              </div>
            </div>
            <div className="row">
              <div className="columns">
                <h2 className="h2 tc-barney jost-med">How is neuroblastoma treated? </h2>
                <p>Treatment in neuroblastoma will vary for children in different <Link rel="glossary" to="/support-and-community/glossary/#risk-group-or-risk-level">risk groups</Link>. Those with <Link rel="glossary" to="/support-and-community/glossary/#high-risk-neuroblastoma">high-risk neuroblastoma</Link> have a higher risk of <Link rel="glossary" to="/support-and-community/glossary/#relapse">relapse</Link> and may receive more intensive therapy than those with <Link rel="glossary" to="/support-and-community/glossary/#non-high-risk-neuroblastoma">non–high-risk</Link> (<Link rel="glossary" to="/support-and-community/glossary/#low-risk-neuroblastoma">low</Link>- or <Link rel="glossary" to="/support-and-community/glossary/#intermediate-risk-neuroblastoma">intermediate-risk</Link>) neuroblastoma.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row row--inner align-center lg_mt-4 mt-4">
          <div className="columns small-11 large-4">
            <div className="card card--comparison" style={{borderColor: SassVars.viridian}}>
              <div className="card__angle-head" style={{background: SassVars.midGrey}}>
                <div className="text-center">
                  <h3 className="jost-bold h3 lg_mt-1 tc-white">Low risk</h3>
                </div>
              </div>
              <h4 className="h4 jost-med tc-viridian">40% of new patients</h4>
              <h4 className="h4 jost-med tc-viridian lg_mt-2">Main treatments</h4>
              <ul className="ul--dotted">
                <li>Observation</li>
                <li>Surgery</li>
              </ul>
              <h4 className="h4 jost-med tc-viridian">Prognosis</h4>
              <p className="p--std">&gt;95% survival</p>
            </div>
          </div>
          <div className="columns small-11 large-4">
            <div className="card card--comparison" style={{borderColor: SassVars.viridian}}>
              <div className="card__angle-head" style={{background: SassVars.viridian}}>
                <div className="text-center">
                  <h3 className="jost-bold h3 lg_mt-1 tc-white">Intermediate risk</h3>
                </div>
              </div>
              <h4 className="h4 jost-med tc-viridian">15% of new patients</h4>
              <h4 className="h4 jost-med tc-viridian lg_mt-2">Main treatments</h4>
              <ul className="ul--dotted">
                <li>Surgery</li>
                <li><Link rel="glossary" to="/support-and-community/glossary/#chemotherapy">Chemotherapy</Link></li>
              </ul>
              <h4 className="h4 jost-med tc-viridian">Prognosis</h4>
              <p className="p--std">&gt;90% survival</p>
            </div>
          </div>
          <div className="columns small-11 large-4">
            <div className="card card--comparison" style={{borderColor: SassVars.viridian}}>
              <div className="card__angle-head" style={{background: SassVars.barney}}>
                <div className="text-center">
                  <h3 className="jost-bold h3 lg_mt-1 tc-white">High risk</h3>
                </div>
              </div>
              <h4 className="h4 jost-med tc-viridian">45-50% of new patients</h4>
              <h4 className="h4 jost-med tc-viridian lg_mt-2">Main treatments</h4>
              <ul className="ul--dotted">
                <li>Chemotherapy</li>
                <li>Surgery</li>
                <li>High-dose chemotherapy with stem cell <Link rel="glossary" to="/support-and-community/glossary/#rescue">rescue</Link></li>
                <li>Radiation therapy</li>
                <li>Immunotherapy</li>
              </ul>
              <h4 className="h4 jost-med tc-viridian">Prognosis</h4>
              <p className="p--std">&lt;60% survival</p>
            </div>
          </div>
        </div>
      </Section>

      <Section style={{backgroundColor: SassVars.paleBlue}}>
        <div className="row row--inner align-middle">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">Non–high-risk (low- or intermediate-risk) neuroblastoma</h3>
            <p>For children with <Link rel="glossary" to="/support-and-community/glossary/#low-risk-neuroblastoma">low-risk neuroblastoma</Link>, surgery to remove the tumor is often the only treatment necessary. Treatment for children with <Link rel="glossary" to="/support-and-community/glossary/#intermediate-risk-neuroblastoma">intermediate-risk neuroblastoma</Link> often includes <Link rel="glossary" to="/support-and-community/glossary/#chemotherapy">chemotherapy</Link> and, if necessary, surgery to remove the tumor as well.</p>
            <p><a href="/treatment/non-high-risk-neuroblastoma/" className="btn btn--primary btn--inline lg_mt-1 mt-1">Learn more about your child’s treatment for non–high-risk (low- or intermediate-risk) neuroblastoma</a></p>
          </div>
        </div>
      </Section>

      <Section>
        <div className="row row--inner align-middle">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">High-risk neuroblastoma</h3>
            <p>For children with <Link rel="glossary" to="/support-and-community/glossary/#high-risk-neuroblastoma">high-risk neuroblastoma</Link>, intensive (strong) treatment that combines <Link rel="glossary" to="/support-and-community/glossary/#chemotherapy">chemotherapy</Link>, surgery, <Link rel="glossary" to="/support-and-community/glossary/#autologous">autologous</Link> <Link rel="glossary" to="/support-and-community/glossary/#stem-cell-transplant">stem cell transplant</Link> (also called bone marrow transplant), radiation therapy, and <Link rel="glossary" to="/support-and-community/glossary/#antibody-therapy">antibody therapy</Link> is usually required.</p>
            <p>Children are considered to have high-risk neuroblastoma either because of aggressive tumor cell characteristics or the presence of disease in multiple places.</p>
            <p><Link to="/treatment/high-risk-neuroblastoma/" className="btn btn--primary btn--inline lg_mt-1 mt-1">Learn more about your child’s treatment for high-risk neuroblastoma</Link></p>
          </div>
        </div>



      </Section>

      <InteriorPageFooter>
      <UpNextCard topic="Preparing for Treatment" title="How should I prepare for treatment?" lead="A little bit of planning before treatment can go a long way in keeping your family on track." url="/diagnosis/treatment-options/" />
      </InteriorPageFooter>

    </Layout>
  )
}

export default UnderstandingTreatmentOptionsPage
